import {
  EmployeeApplicationSettingsParams,
  EmployeeFaceToFaceReviewersModel,
  EmployeeNotificationSettingsParams,
  OfficeType,
  TrainingPlanAssignSettingModel,
} from '../../../shared/models/settings.models';

export class GetEmployeeApplicationSettings {
  static readonly type = '[Settings] Get employee application settings';
}

export class SetEmployeeApplicationSettings {
  static readonly type = '[Settings] Set employee application settings';

  constructor(
    public params: EmployeeApplicationSettingsParams,
    public changes: {
      deleteUsers: boolean;
      setSettings: boolean;
    },
  ) {}
}

export class MarkForDeleteEmployeeApplicationSettings {
  static readonly type = '[Settings] Delete employee form application settings';

  constructor(
    public idEmpl: string,
    public unMark = false,
  ) {}
}

export class GetUsersForEmployeeApplicationSettings {
  static readonly type = '[Settings] Get users employee form application settings';

  constructor(public search?: string) {}
}

export class SetInterviewer {
  static readonly type = '[Employee form] Set Interviewer';

  constructor(public interviewer: EmployeeFaceToFaceReviewersModel) {}
}

export class GetEmployeeFaceToFaceReviewersListSetting {
  static readonly type = '[Settings] Get Employee Face To Face Reviewers List Setting';
}

export class UpdateEmployeeFaceToFaceReviewersListSetting {
  static readonly type = '[Settings] Update Employee Face To Face Reviewers List Setting';

  constructor(public users?: string[]) {}
}

export class DeleteEmployeeFaceToFaceReviewer {
  static readonly type = '[Settings] Delete Employee Face To Face Reviewer';

  constructor(public uid: string) {}
}

export class ResetEmployeeApplicationSettings {
  static readonly type = '[Settings] Reset  Employee Application  settings';
}

export class GetEmployeeNotificationSettings {
  static readonly type = '[Settings] Get notification  settings';
}

export class SetEmployeeNotificationSettings {
  static readonly type = '[Settings] Set notification  settings';

  constructor(
    public params: EmployeeNotificationSettingsParams,
    public changes: {
      deleteUsers: boolean;
      setSettings: boolean;
    },
  ) {}
}

export class ResetEmployeeNotificationSettings {
  static readonly type = '[Settings] Reset  Employee Notification  settings';
}

export class GetTrainingPlanAssignSetting {
  static readonly type = '[Settings] GetTrainingPlanAssignSetting';
}

export class UpdateTrainingPlanAssignSetting {
  static readonly type = '[Settings] UpdateTrainingPlanAssignSetting';

  constructor(public payload: TrainingPlanAssignSettingModel) {}
}

export class GetTrainingPlansForAssign {
  static readonly type = '[Settings] GetTrainingPlansForAssign';

  constructor(public search: string) {}
}

export class ClearTrainingPlans {
  static readonly type = '[Settings] ClearTrainingPlans';
}

export class GetTypeSignatureStatus {
  static readonly type = '[Settings] Get Type Signature Status';
}

export class ChangeTypeSignatureStatus {
  static readonly type = '[Settings] Change Type Signature Status';
}

export class SetTypeSignatureStatus {
  static readonly type = '[Settings] Set Type Signature Status';

  constructor(public isEnable: boolean) {}
}

export class GetAlreadySavedListOfUsersByRoles {
  static readonly type = '[Settings] Get Already Saved List Of Users By Roles';

  constructor(public type: OfficeType) {}
}

export class ClearAlreadySavedListOfUsersByRoles {
  static readonly type = '[Settings] Clear Already Saved List Of Users By Roles';

  constructor(public type: OfficeType) {}
}

export class GetUsersByRoles {
  static readonly type = '[Settings] Get Users by Roles';

  constructor(
    public roleIds: string[],
    public type: OfficeType,
    public search?: string,
  ) {}
}

export class UpdateUsersByRoles {
  static readonly type = '[Settings] Update Users By Roles';

  constructor(
    public type: OfficeType,
    public roleIds: string[],
    public users: Array<string | number>,
  ) {}
}
